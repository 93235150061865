import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Client } from 'src/app/commons/models/client.model';
import { Session, SessionType } from 'src/app/commons/models/session.model';
import { Role, User } from 'src/app/commons/models/user.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import { fileExtensionValidator, fileSizeValidator } from 'src/app/helpers/file.validator';
import * as ClientActions from 'src/app/store/actions/client.actions';
import * as UserActions from 'src/app/store/actions/user.actions';
import { AppState } from 'src/app/store/reducers';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

@Component({
  selector: "app-user-edit",
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.scss"],
})
export class UserEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _user: User;
  types: SessionType[] = [/*"local",*/ "send_app", "socket", "official"];

  accepts: string[] = ["jpg", "png", "jpeg"];
  readonly maxSize = 15 * 1024 * 1024; // 15MB

  currentUser: User;
  roles: Role[];
  client: Client;
  session: Session;

  userForm: UntypedFormGroup;
  constructor(
    private store$: Store<AppState>,
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.store$
      .pipe(
        select(AuthSelectors.getCurrentUser),
        takeUntil(this.unsubscribe$),
        map((dto) => (dto ? new User(dto) : null))
      )
      .subscribe((user) => (this.currentUser = user));
  }

  ngOnInit() {
    if (this.data) {
      this.user = this.data.user;

      if (this.user) {
        this.user.clients.map((client) => (this.client = client));
      }
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateFunction(name: string, has: boolean, value: boolean) {
    const control = this.userForm.get(name);
    if (has) {
      control.enable();
      control.patchValue(value);
    } else {
      control.disable();
      control.patchValue(false);
    }
  }

  private _createForm() {
    let group = {
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      role: ["", Validators.required],
      hasSequence: [""],
      hasMassMessage: [""],
      hasAutoResponder: [""],
      hasSingleMessage: [""],
      showSequence: [""],
      showMassMessage: [""],
      showAutoResponder: [""],
      showSingleMessage: [""],
      statusPhone: [""],
      statusEmails: [""],
      statusHook: [""],
      profile: [""],
      expiration: [""],
      amount: [""],
      paymentMethod: [""],
      logo: [
        "",
        [fileSizeValidator(this.maxSize), fileExtensionValidator(this.accepts)],
      ],
      watiHook: [false],
      color: [""],
      partner: [""],
      active: [true],
      logoutRedirectUrl: [""],
      failedMessageHook: [""]
    };
    if (this.user) {
      this.userForm = this.fb.group(group);
    } else {
      group["password"] = ["", Validators.required];
      group["confirmPass"] = ["", Validators.required];
      group["clientName"] = ["", Validators.required];
      group["clientPhone"] = ["", Validators.required];
      group["clientSessionType"] = [""];
      group["clientSessionToken"] = [""];
      group["clientSessionId"] = [""];
      group["waNumId"] = [""];
      group["waToken"] = [""];
      group["waAppId"] = [""];
      group["waPhoneId"] = [""];

      this.userForm = this.fb.group(group, {
        validator: this.checkIfMatchingPasswords("password", "confirmPass"),
      });
    }

    this.userForm
      .get("hasSequence")
      .valueChanges.pipe()
      .subscribe((value) => {
        this.updateFunction(
          "showSequence",
          value,
          this.userForm.get("showSequence").value
        );
      });

    this.userForm
      .get("hasMassMessage")
      .valueChanges.pipe()
      .subscribe((value) => {
        this.updateFunction(
          "showMassMessage",
          value,
          this.userForm.get("showMassMessage").value
        );
      });

    this.userForm
      .get("hasAutoResponder")
      .valueChanges.pipe()
      .subscribe((value) => {
        this.updateFunction(
          "showAutoResponder",
          value,
          this.userForm.get("showAutoResponder").value
        );
      });

    this.userForm
      .get("hasSingleMessage")
      .valueChanges.pipe()
      .subscribe((value) => {
        this.updateFunction(
          "showSingleMessage",
          value,
          this.userForm.get("showSingleMessage").value
        );
      });

    if (!this.user) {
      this.userForm.get("clientSessionType").valueChanges.subscribe((value) => {
        if (value == "send_app") {
          console.log("send_app");

          this.userForm
            .get("clientSessionToken")
            .setValidators([Validators.required]);
          this.userForm
            .get("clientSessionId")
            .setValidators([Validators.required]);
        } else {
          this.userForm.get("clientSessionToken").clearValidators();
          this.userForm.get("clientSessionId").clearValidators();
        }
        this.userForm.get("clientSessionToken").updateValueAndValidity();
        this.userForm.get("clientSessionId").updateValueAndValidity();

        if (value == "official") {
          this.userForm
            .get("waNumId")
            .setValidators([Validators.required]);
          this.userForm
            .get("waToken")
            .setValidators([Validators.required]);
          this.userForm
            .get("waAppId")
            .setValidators([Validators.required]);
          this.userForm
            .get("waPhoneId")
            .setValidators([Validators.required]);
        } else {
          this.userForm.get("waNumId").clearValidators();
          this.userForm.get("waToken").clearValidators();
          this.userForm.get("waAppId").clearValidators();
          this.userForm.get("waPhoneId").clearValidators();
        }
        this.userForm.get("waNumId").updateValueAndValidity();
        this.userForm.get("waToken").updateValueAndValidity();
        this.userForm.get("waAppId").updateValueAndValidity();
        this.userForm.get("waPhoneId").updateValueAndValidity();
      });
    }
    this.userForm.get("role").valueChanges.subscribe((value) => {
      if (value == "client") {
        this.userForm.get("clientName")?.setValidators([Validators.required]);
        this.userForm.get("clientPhone")?.setValidators([Validators.required]);
        this.userForm
          .get("clientSessionType")
          ?.setValidators([Validators.required]);
        this.userForm.get("profile")?.setValidators([Validators.required]);
        this.userForm.get("statusPhone")?.setValidators([Validators.required]);
      } else {
        this.userForm.get("clientName")?.clearValidators();
        this.userForm.get("clientPhone")?.clearValidators();
        this.userForm.get("clientSessionType")?.clearValidators();
        this.userForm.get("profile")?.clearValidators();
        this.userForm.get("statusPhone")?.clearValidators();

        this.userForm.patchValue({
          hasSequence: true,
          hasMassMessage: true,
          hasAutoResponder: true,
        });
      }
      this.userForm.get("clientName")?.updateValueAndValidity();
      this.userForm.get("clientPhone")?.updateValueAndValidity();
      this.userForm.get("clientSessionType")?.updateValueAndValidity();
    });
  }

  checkIfMatchingPasswords(
    passwordKey: string,
    passwordConfirmationKey: string
  ) {
    return (group: UntypedFormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  get isSendApp(): boolean {
    return this.userForm.value.clientSessionType == "send_app";
  }

  get isOfficial(): boolean {
    return this.userForm.value.clientSessionType == "official";
  }

  get isClient(): boolean {
    return this.userForm.value.role == "client";
  }

  private ngOnChanges() {
    if (this.userForm) {
      this.userForm.reset();
      if (this.user) {
        this._initFormValues(this.user);
      } else {
        this._initFormValues();
      }
    }
  }

  private _initFormValues(user?: User) {
    if (user) {
      this.updateFunction("showSequence", user.hasSequence, user.showSequence);
      this.updateFunction(
        "showAutoResponder",
        user.hasAutoResponder,
        user.showAutoResponder
      );
      this.updateFunction(
        "showMassMessage",
        user.hasMassMessage,
        user.showMassMessage
      );


      this.userForm.patchValue({
        name: user.name,
        email: user.email,
        password: user.password,
        role: user.role,
        hasSequence: user.hasSequence,
        hasAutoResponder: user.hasAutoResponder,
        hasMassMessage: user.hasMassMessage,
        hasSingleMessage: user.hasSingleMessage,
        showSequence: user.showSequence,
        showAutoResponder: user.showAutoResponder,
        showMassMessage: user.showMassMessage,
        showSingleMessage: user.showSingleMessage,
        statusPhone: user.statusPhone,
        statusEmails: user.statusEmails,
        statusHook: user.statusHook,
        profile: user.profile,
        expiration: user.expiration,
        amount: user.amount,
        paymentMethod: user.paymentMethod,
        logo: user.logoPath,
        watiHook: user.watiHook,
        active: user.active,
        color: user.color,
        partner: user.partner,
        logoutRedirectUrl: user.logoutRedirectUrl,
        failedMessageHook: user.failedMessageHook
      });
    } else {
      this.userForm.patchValue({ active: true });
    }
  }

  get user(): User {
    return this._user;
  }

  set user(user: User) {
    this._user = user;
    this.ngOnChanges();
  }

  get isPartner(): boolean {
    return this.userForm.get('role').value === 'partner'
  }

  get logoPlaceholder(): string {
    if (
      this.user?.logoPath &&
      this.user?.logoPath === this.userForm.get("logo").value
    ) {
      return this.userForm.get("logo").value.split("/")[2];
    }

    return "Seleziona logo";
  }

  private _prepareSaveUser(): User {
    let savingUser: User = User.fromFormGroup(this.userForm, this.user);
    return savingUser;
  }

  save() {
    let unsavedEntity = this._prepareSaveUser();
    this.store$.dispatch(UserActions.saveUser({ user: unsavedEntity }));
  }

  close() {
    if (this.userForm.pristine) {
      this.store$.dispatch(UserActions.closeUserDialog());
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe((result) => {
          if (result) {
            this.store$.dispatch(UserActions.closeUserDialog());
          }
        });
    }
  }

  deleteUser() {
    if (this.user) {
      this.store$.dispatch(UserActions.deleteUser({ user: this.user.toDTO() }));
    }
  }

  changePassword() {
    if (this.user) {
      this.store$.dispatch(
        UserActions.changeUserPassword({ user: this.user.toDTO() })
      );
    }
  }

  revert() {
    this.ngOnChanges();
  }

  goToClient() {
    this.store$.dispatch(
      ClientActions.selectClient({ client: this.client.toDTO() })
    );
    this.close();
  }

  testDisconnectionHook() {
    if (this.user) {
      this.store$.dispatch(UserActions.testDisconnectionWebhook({ user: this.user.toDTO() }));
    }
  }

  testFailedMessageHook() {
    if (this.user) {
      this.store$.dispatch(UserActions.testMessageFailedWebhook({ user: this.user.toDTO() }));
    }
  }

  clearLogo() {
    const logo = this.userForm.get("logo");
    logo.setValue(null);
    logo.updateValueAndValidity();
  }
}
