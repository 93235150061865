/* eslint-disable @ngrx/on-function-explicit-return-type */
import { Action, createReducer, on } from '@ngrx/store';
import { ClientDTO } from 'src/app/commons/models/client.model';

import * as ClientActions from '../actions/client.actions';
import * as TemplatesMessageActions from '../actions/templates-messages.actions';
import { TemplatesMessageDTO, TemplatesMessageFilters } from 'src/app/commons/models/templates-messages.model';

export interface TemplatesMessageState {
  list: TemplatesMessageDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: TemplatesMessageFilters,
  dialogId: string,
  templateMessage: TemplatesMessageDTO,
  sessionId: number,
  clients: ClientDTO[]
};

const initialState: TemplatesMessageState = {
  list: [],
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  templateMessage: null,
  sessionId: null,
  clients: null
};

const templatesMessageReducer = createReducer(
  initialState,
  on(ClientActions.loadClientCompleted, (state, { client }) => {
    return { ...state, sessionId: client?.sessions?.length > 0 ? client.sessions[0]?.id : null };
  }),
  on(TemplatesMessageActions.loadTemplatesMessageCompleted, (state, { templatesMessage, currentPage, total, perPage, order, direction, filters }) => {
    console.log('Reducer updating state with:', { templatesMessage, currentPage, total, perPage, order, direction, filters });
    return {
      ...state,
      list: templatesMessage,
      currentPage,
      total,
      perPage,
      order,
      direction,
      filters
    };
  }),
  on(TemplatesMessageActions.saveTemplateMessageCompleted, (state, { templateMessage }) => {
    return { ...state, templateMessage };
  }),
  on(TemplatesMessageActions.templateMessageDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(TemplatesMessageActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(TemplatesMessageActions.loadTemplateMessageCompleted, (state, { templateMessage }) => {
    return { ...state, templateMessage };
  }),
  on(TemplatesMessageActions.saveTemplateMessageCompleted, (state, { templateMessage }) => {
    return { ...state, templateMessage };
  }),
  on(TemplatesMessageActions.selectTemplateMessage, (state, { templateMessage }) => {
    return { ...state, templateMessage: templateMessage, sessionId: templateMessage.session_id };
  }),
);

export function reducer(state: TemplatesMessageState | undefined, action: Action) {
  return templatesMessageReducer(state, action);
}
