import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, flatMap, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { UserDTO } from 'src/app/commons/models/user.model';
import { PasswordChangeComponent } from 'src/app/modules/home/users/user-edit/password-change/password-change.component';
import { PaymentInfoComponent } from 'src/app/modules/home/users/user-edit/payment-info/payment-info.component';
import { UserEditComponent } from 'src/app/modules/home/users/user-edit/user-edit.component';
import { UserSelectionComponent } from 'src/app/modules/shared/user-selection/user-selection.component';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';
import * as UserSelectors from 'src/app/store/selectors/user.selectors';

import { AlertService } from '../../commons/services/alert.service';
import { LaravelUserService } from '../../commons/services/backend/laravel-user.service';
import * as UserActions from '../actions/user.actions';
import { AppState } from '../reducers';
import { getUserDialogId, getUsersTableState } from '../selectors/user.selectors';

@Injectable()
export class UserEffects {
  error$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.saveUserFailed, UserActions.testDisconnectionWebhookFailed, UserActions.testMessageFailedWWebhookFailed),
        tap(({ error }) => {
          if (error) {
            this.alertService.showErrorMessage("Errore", error);
          }
        })
      );
    },
    { dispatch: false }
  );

  loadUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUsers),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.userService
          .list(page, perPage, order, direction, filters, includes)
          .pipe(
            map((result) =>
              UserActions.loadUsersCompleted({
                users: result.data,
                currentPage: page,
                total: result.total,
                perPage,
                order,
                direction,
                filters,
                includes,
              })
            ),
            catchError((error) => {
              return of(UserActions.loadUsersFailed({ error }));
            })
          );
      })
    );
  });

  changePage = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.changePage),
      concatLatestFrom(() => this.store$.select(getUsersTableState)),
      map(
        ([
          { page, size },
          { total, currentPage, perPage, direction, order, filters, includes },
        ]) =>
          UserActions.loadUsers({
            page: page,
            perPage: size,
            order,
            direction,
            filters,
            includes,
          })
      )
    );
  });

  changeSort = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.changeSort),
      concatLatestFrom(() => this.store$.select(getUsersTableState)),
      map(
        ([
          action,
          { total, currentPage, perPage, direction, order, filters, includes },
        ]) =>
          UserActions.loadUsers({
            page: currentPage,
            perPage: perPage,
            order: action.order,
            direction: action.direction,
            filters,
            includes,
          })
      )
    );
  });

  changeFilters = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.changeFilters),
      concatLatestFrom(() => this.store$.select(getUsersTableState)),
      map(
        ([
          { filters },
          { total, currentPage, perPage, direction, order, includes },
        ]) =>
          UserActions.loadUsers({
            page: currentPage,
            perPage: perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    );
  });

  editUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.editUser),
      map(({ user }) => {
        let dialogRef = this.dialog.open(UserEditComponent, {
          data: {
            user,
          },
        });
        return UserActions.userDialogOpened({ dialogId: dialogRef.id });
      })
    );
  });

  saveUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.saveUser),
      mergeMap(({ user }) =>
        this.userService.upsert(user.toDTO()).pipe(
          map((result) => UserActions.saveUserCompleted({ user: result })),
          catchError((error) => of(UserActions.saveUserFailed({ error })))
        )
      )
    );
  });

  onSaveCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.saveUserCompleted),
      map((action) => action.user),
      tap((user) =>
        this.alertService.showConfirmMessage(
          `${user.name} salvata con successo`
        )
      ),
      map(() => UserActions.closeUserDialog())
    );
  });

  changeUserPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.changeUserPassword),
      map(({ user }) => {
        let dialogRef = this.dialog.open(PasswordChangeComponent, {
          data: {
            user,
          },
        });
        return UserActions.changePasswordDialogOpen({ dialogId: dialogRef.id });
      })
    );
  });

  updatePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updatePassword),
      flatMap(({ newPassword, user }) => {
        if (user) {
          return of({ newPassword, user });
        } else {
          return this.store$.select(AuthSelectors.getCurrentUser).pipe(
            take(1),
            map((user) => {
              return { newPassword, user };
            })
          );
        }
      }),
      switchMap(({ newPassword, user }) => {
        let newUser: UserDTO = JSON.parse(JSON.stringify(user));
        newUser.password = newPassword;
        return this.userService.upsert(newUser).pipe(
          map((user) => UserActions.updatePasswordCompleted({ user })),
          catchError((error) => {
            return of(UserActions.updatePasswordFailed({ error }));
          })
        );
      })
    );
  });

  onUpdatePasswordCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updatePasswordCompleted),
      map((action) => action.user),
      tap((user) =>
        this.alertService.showConfirmMessage(
          `Password per ${user.email} aggiornata con successo`
        )
      ),
      map(() => UserActions.closeChangePasswordDialog())
    );
  });

  closeChangePasswordDialog = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.closeChangePasswordDialog),
        concatLatestFrom(() =>
          this.store$.select(UserSelectors.getChangePasswordDialogId)
        ),
        tap(([_, dialogId]) => {
          if (dialogId) {
            this.dialog.getDialogById(dialogId).close();
          }
        })
      );
    },
    { dispatch: false }
  );

  deleteUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deleteUser),
      switchMap(({ user }) =>
        this.alertService
          .showConfirmDialog(
            "Conferma eliminazione",
            `Sei sicuro di voler eliminare l'utente ${user.email}?`
          )
          .pipe(
            mergeMap((confirm) => {
              return confirm
                ? this.userService.delete(user.id).pipe(
                  map(() => UserActions.deleteUserCompleted({ user })),
                  catchError((error) =>
                    of(UserActions.deleteUserFailed({ error }))
                  )
                )
                : of(UserActions.deleteUserCancelled());
            })
          )
      )
    );
  });

  onDeleteCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deleteUserCompleted),
      tap(({ user }) =>
        this.alertService.showConfirmMessage(
          `Utente ${user.name} eliminato con successo`
        )
      ),
      map(() => UserActions.closeUserDialog())
    );
  });

  closeDialog = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.closeUserDialog),
        concatLatestFrom(() => this.store$.select(getUserDialogId)),
        tap(([_, dialogId]) => {
          if (dialogId) {
            this.dialog.getDialogById(dialogId).close();
          }
        })
      );
    },
    { dispatch: false }
  );

  reloadAfterSave = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.saveUserCompleted),
      concatLatestFrom(() => this.store$.select(getUsersTableState)),
      map(
        ([_, { currentPage, perPage, direction, order, filters, includes }]) =>
          UserActions.loadUsers({
            page: currentPage,
            perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    );
  });

  reloadAfterDelete = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deleteUserCompleted),
      concatLatestFrom(() => this.store$.select(getUsersTableState)),
      map(
        ([_, { currentPage, perPage, direction, order, filters, includes }]) =>
          UserActions.loadUsers({
            page: currentPage,
            perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    );
  });

  showPaymentInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.showPaymentInfo),
      map(({ user }) => {
        let dialogRef = this.dialog.open(PaymentInfoComponent, {
          data: {
            user,
          },
          width: '400px'
        });
        return UserActions.paymentInfoDialogOpen({ dialogId: dialogRef.id });
      })
    );
  });

  closePaymentInfoDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.closePaymentInfoDialog),
      map(() => UserActions.closeUserDialog())
    );
  });

  selectUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.selectUser),
      map(({ filters }) => {
        let dialogRef = this.dialog.open(UserSelectionComponent, {
          data: {
            defaultFilters: filters
          }
        });
        return UserActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
      }))
  }
  );

  loadOnSelectUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.selectUser),
      concatLatestFrom(() => this.store$.select(UserSelectors.getUsersSelectionTableState)),
      switchMap(([{ filters }, { currentPage, perPage, order, direction, includes }]) => {
        return this.userService
          .list(currentPage, perPage, order, direction, filters, includes)
          .pipe(
            map((result) =>
              UserActions.loadSelectionUsersCompleted({
                users: result.data,
                currentPage,
                total: result.total,
                perPage,
                order,
                direction,
                filters,
                includes,
              })
            ),
            catchError((error) => {
              return of(UserActions.loadUsersFailed({ error }));
            })
          );
      })
    )
  });

  closeSelectionDialog = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.closeSelectionDialog),
      concatLatestFrom(() => this.store$.select(UserSelectors.getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    )
  }, { dispatch: false }
  );

  usersSelected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.userSelected),
      map(() => UserActions.closeSelectionDialog())
    )
  })


  testDisconnectionWebhook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.testDisconnectionWebhook),
      filter(({ user }) => !!user),
      switchMap(({ user }) =>
        this.userService.testDisconnectionWebhook(user.id).pipe(
          map(() => UserActions.testDisconnectionWebhookCompleted({ user })),
          catchError((error) => of(UserActions.testDisconnectionWebhookFailed({ error })))
        )
      )
    );
  });

  testMessageFailedWebhook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.testMessageFailedWebhook),
      filter(({ user }) => !!user),
      switchMap(({ user }) =>
        this.userService.testMessageFailedWebhook(user.id).pipe(
          map(() => UserActions.testMessageFailedWWebhookCompleted({ user })),
          catchError((error) => of(UserActions.testMessageFailedWWebhookFailed({ error })))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private userService: LaravelUserService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }
}
