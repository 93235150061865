import { createSelector } from '@ngrx/store';
import { ClientDTO } from 'src/app/commons/models/client.model';
import { SessionDTO } from 'src/app/commons/models/session.model';

import { selectClientState } from '../reducers';
import { ClientState } from '../reducers/client.reducer';

export const getClientsTableState = createSelector(
  selectClientState,
  (state: ClientState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getClients = createSelector(
  selectClientState,
  (state: ClientState) => state.list
);

export const getClient = createSelector(
  selectClientState,
  (state: ClientState) => state.client
);

export const getClientId = createSelector(
  selectClientState,
  (state: ClientState) => state.client?.id ?? state.clientId
);

export const getTotalClients = createSelector(
  getClientsTableState,
  (tableState) => tableState.total
);

export const getClientsCurrentPage = createSelector(
  getClientsTableState,
  (tableState) => tableState.currentPage
);

export const getClientsPerPage = createSelector(
  getClientsTableState,
  (tableState) => tableState.perPage
);

export const getClientsOrder = createSelector(
  getClientsTableState,
  (tableState) => tableState.order
);

export const getClientsDirection = createSelector(
  getClientsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getClientsTableState,
  (tableState) => tableState.filters
);

export const getClientDialogId = createSelector(
  selectClientState,
  (state: ClientState) => state.dialogId
);

export const getSession = createSelector(
  getClient,
  (client: ClientDTO) => !!client?.sessions ? client?.sessions[0] : null
)

export const getSessionWaNumId = createSelector(
  getSession,
  (session: SessionDTO) => session?.wa_num_id
)

export const getSessionsWaToken = createSelector(
  getSession,
  (session: SessionDTO) => session?.wa_token
)

export const getSessionWaAppId = createSelector(
  getSession,
  (session: SessionDTO) => session?.wa_app_id
)
