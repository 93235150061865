import { UntypedFormGroup } from '@angular/forms';

import { Base, BaseDTO } from './base.model';
import { Client, ClientDTO } from './client.model';
import { SessionType } from './session.model';

export type Role = "admin" | "partner" | "client";

export type Profile =
  | "Fase di Test"
  | "Subscription Mensile"
  | "Subscription Annuale";

export interface UserFilters {
  search?: string;
  roles?: Role[];
}

export interface UserDTO extends BaseDTO {
  name?: string;
  surname?: string;
  email?: string;
  username: string;
  password: string;
  user_roles: Role[];
  client_name: string;
  client_phone: string;
  client_session_type: SessionType;
  client_session_token: string;
  client_session_id: string;
  wa_token?: string;
  wa_num_id?: string;
  wa_app_id?: string;
  wa_phone_id?: string;
  clients: ClientDTO[];
  has_sequence: boolean;
  has_mass_message: boolean;
  has_auto_responder: boolean;
  has_single_message: boolean;
  show_sequence: boolean;
  show_mass_message: boolean;
  show_auto_responder: boolean;
  show_single_message: boolean;
  has_api_key: boolean;
  api_key: string;
  status_phone?: string;
  status_emails?: string;
  status_hook?: string;
  profile?: Profile;
  expiration?: string;
  amount?: string;
  payment_method?: string;
  logo?: string;
  logo_path?: string;
  wati_hook: boolean;
  active: boolean;
  partner_id?: string;
  partner?: UserDTO;
  partner_name?: string;
  partner_users?: number;
  color?: string;
  failed_messages?: number;
  logout_redirect_url?: string;
  failed_message_hook?: string;
}
export class User extends Base {
  email?: string;
  surname: string;
  username: string;
  name: string;
  password: string;
  role?: Role;
  clientName: string;
  clientPhone: string;
  clientSessionType: SessionType;
  clientSessionToken: string;
  clientSessionId: string;
  waToken?: string;
  waNumId?: string;
  waAppId?: string;
  waPhoneId?: string;
  clients: Client[];
  hasSequence: boolean;
  hasMassMessage: boolean;
  hasAutoResponder: boolean;
  hasSingleMessage: boolean;
  showSequence: boolean;
  showMassMessage: boolean;
  showAutoResponder: boolean;
  showSingleMessage: boolean;
  hasApiKey: boolean;
  apiKey: string;
  statusPhone?: string;
  statusEmails?: string;
  statusHook?: string;
  profile?: Profile;
  expiration?: string;
  amount?: string;
  paymentMethod?: string;
  logo?: any;
  logoPath?: string;
  watiHook: boolean;
  active: boolean;
  partnerId?: string;
  partner?: User;
  partnerName?: string;
  partnerUsers?: number;
  color?: string;
  failedMessages?: number;
  logoutRedirectUrl?: string;
  failedMessageHook?: string;

  constructor(source: UserDTO) {
    super(source);
    if (source) {
      this.name = source.name;
      this.email = source.email;
      this.password = source.password;
      this.clientName = source.client_name;
      this.clientPhone = source.client_phone;
      this.clientSessionType = source.client_session_type;
      this.clientSessionToken = source.client_session_token;
      this.waToken = source.wa_token;
      this.waNumId = source.wa_num_id;
      this.waAppId = source.wa_app_id;
      this.waPhoneId = source.wa_phone_id;
      this.clientSessionId = source.client_session_id;
      if (source.user_roles) {
        this.role = source.user_roles[0];
      }
      if (source.clients) {
        this.clients = source.clients.map((c) => new Client(c));
        this.addLoadedRelation("clients");
      }
      this.hasAutoResponder = source.has_auto_responder;
      this.hasMassMessage = source.has_mass_message;
      this.hasSequence = source.has_sequence;
      this.hasSingleMessage = source.has_single_message;
      this.showSequence = source.show_sequence;
      this.showMassMessage = source.show_mass_message;
      this.showAutoResponder = source.show_auto_responder;
      this.showSingleMessage = source.show_single_message;
      this.hasApiKey = source.has_api_key;
      this.apiKey = source.api_key;
      if (source.status_phone) this.statusPhone = source.status_phone;
      if (source.status_emails) this.statusEmails = source.status_emails;
      if (source.status_hook) this.statusHook = source.status_hook;
      this.profile = source.profile;
      this.expiration = source.expiration;
      this.amount = source.amount;
      this.paymentMethod = source.payment_method;
      this.logoPath = source.logo_path;
      this.watiHook = source.wati_hook;
      this.active = source.active;
      this.partnerId = source.partner_id;
      if (source.partner) {
        this.partner = new User(source.partner);
        this.partnerName = source.partner_name;
        this.addLoadedRelation("partner");
      }
      this.partnerUsers = source.partner_users;
      this.color = source.color;
      this.failedMessages = source.failed_messages;
      this.logoutRedirectUrl = source.logout_redirect_url;
      this.failedMessageHook = source.failed_message_hook;
    }
  }

  toDTO(): UserDTO {
    let result: UserDTO = <UserDTO>super.toDTO();
    result.name = this.name;
    result.email = this.email;
    result.password = this.password;
    result.client_name = this.clientName;
    result.client_phone = this.clientPhone;
    result.client_session_type = this.clientSessionType;
    result.client_session_token = this.clientSessionToken;
    result.client_session_id = this.clientSessionId;
    result.wa_token = this.waToken;
    result.wa_num_id = this.waNumId;
    result.wa_app_id = this.waAppId;
    result.user_roles = [];
    result.has_auto_responder = this.hasAutoResponder;
    result.has_mass_message = this.hasMassMessage;
    result.has_sequence = this.hasSequence;
    result.has_single_message = this.hasSingleMessage;
    result.show_auto_responder = this.showAutoResponder;
    result.show_mass_message = this.showMassMessage;
    result.show_sequence = this.showSequence;
    result.show_single_message = this.showSingleMessage;
    result.status_phone = this.statusPhone;
    result.status_emails = this.statusEmails;
    result.status_hook = this.statusHook;
    result.profile = this.profile;
    result.expiration = this.expiration;
    result.amount = this.amount;
    result.payment_method = this.paymentMethod;
    result.logo = this.logo;
    result.logo_path = this.logoPath;
    result.wati_hook = this.watiHook;
    result.active = this.active;
    result.partner_id = this.partnerId;
    result.color = this.color;
    result.logout_redirect_url = this.logoutRedirectUrl;
    result.failed_message_hook = this.failedMessageHook;

    if (this.role) {
      result.user_roles.push(this.role);
    }
    if (this.clients) {
      result.clients = this.clients.map((c) => c.toDTO());
    }
    return result;
  }

  static fromFormGroup(formGroup: UntypedFormGroup, original?: User) {
    const formModel = formGroup.getRawValue();
    let user: User = new User(null);
    user.name = formModel.name;
    user.email = formModel.email;
    user.password = formModel.password;
    user.role = formModel.role;
    user.clientName = formModel.clientName;
    user.clientPhone = formModel.clientPhone;
    user.clientSessionType = formModel.clientSessionType;
    user.clientSessionToken = formModel.clientSessionToken;
    user.waToken = formModel.waToken;
    user.waNumId = formModel.waNumId;
    user.waAppId = formModel.waAppId;
    user.clientSessionId = formModel.clientSessionId;
    user.clients = formModel.clients;
    user.hasAutoResponder = formModel.hasAutoResponder;
    user.hasMassMessage = formModel.hasMassMessage;
    user.hasSequence = formModel.hasSequence;
    user.hasSingleMessage = formModel.hasSingleMessage;
    user.showAutoResponder = formModel.showAutoResponder;
    user.showMassMessage = formModel.showMassMessage;
    user.showSequence = formModel.showSequence;
    user.showSingleMessage = formModel.showSingleMessage;
    user.statusPhone = formModel.statusPhone;
    user.statusEmails = formModel.statusEmails;
    user.statusHook = formModel.statusHook;
    user.profile = formModel.profile;
    user.expiration = formModel.expiration;
    user.amount = formModel.amount;
    user.paymentMethod = formModel.payment_method;
    user.watiHook = formModel.watiHook;
    user.active = formModel.active;
    if (formModel.logo?.name) {
      user.logo = formModel.logo;
    } else {
      user.logoPath = formModel.logo;
    }
    if (formModel.partner) {
      user.partner = formModel.partner;
      user.partnerId = formModel.partner?.id;
    }
    user.color = formModel.color;
    user.logoutRedirectUrl = formModel.logoutRedirectUrl;
    user.failedMessageHook = formModel.failedMessageHook;

    if (original) {
      user.id = original.id;
    }
    return user;
  }

  get admin(): boolean {
    return this.hasRole("admin");
  }

  get client(): boolean {
    return this.hasRole("client");
  }

  get partnerColor(): string {
    return this.partner?.color;
  }

  get logoutUrl(): string {
    return this.partner?.logoutRedirectUrl;
  }

  hasRole(role: Role): boolean {
    return this.role && this.role === role;
  }
}
